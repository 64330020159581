import { useEffect, useState } from 'react';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


const ImpactTable = ({ SiteStructureId, SiteName }) => {
  const [evaluationPerspectives, setEvaluationPerspectives] = useState();
  const [ratingDescriptions, setRatingDescriptions] = useState();
  const [evaluationsUsed, setEvaluationsUsed] = useState();
  const [ratingTable, setRatingTable] = useState();

  const [editModal, setEditModal] = useState(false);

  const toggle = () => setEditModal(!editModal);

  const loadData = async () => {
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();
    //let loadCounter = 3;


    try {
      const response = await fetch(`/api/evaluationperspectives/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setEvaluationPerspectives(data);
        //console.log(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('/api/impactperspectivediz/listforcompany/' + userData.CompanyId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setRatingDescriptions(data);
        //setLoading(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/sitestructurelevels/ListForSiteStructure/${SiteStructureId}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data.length);
        //console.log(data[0].EvaluationsUsed);
        if (data.length > 0 && data[0].EvaluationsUsed.length > 0) {
          setEvaluationsUsed(JSON.parse(data[0].EvaluationsUsed));
        }
        else
          setEvaluationsUsed([]);

        for (var i = 0; i < data.length; i++) {
          if (!data[i].EvaluationPerspectiveIds) {
            data[i].EvaluationPerspectiveIds = [];
            data[i].EvaluationPerspectiveDescriptions = [];
            evaluationPerspectives.map((item) => {
              data[i].EvaluationPerspectiveIds.push(item.Id);
              data[i].EvaluationPerspectiveDescriptions.push({ Id: item.Id, Description: "" });
            });
          };
        }

        setRatingTable(data);
        //console.log(evaluationsUsed);
        //console.log(data);

      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getPerspectiveDescriptionWithDefault = (level, band, perspective) => {
    try {
      let table = ratingTable;
      let row = table.find((item) => { return item.LevelNumber === level });
      let idx = row.EvaluationPerspectiveIds.indexOf(perspective);
      let diz = row.EvaluationPerspectiveDescriptions[idx].Description;
      if (diz === "")
        diz = ratingDescriptions.find((item) => { return item.ImpactBandId === band && item.EvaluationPerspectiveId === perspective }).Description;
      return { __html: diz };
    } catch (e) {
      //return "";
      return { __html: "" };
    }
  }

  const renderMaximum = (level, value) => {
    if (level < ratingTable.length ) {
      return value.toNumberString()
    }
    else return "Infinity";
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>

      <button type='button' className='btn btn-info' onClick={toggle}><i className="fa-solid fa-table me-2"></i>Show Impact Table</button>


      <Modal isOpen={editModal} toggle={toggle} scrollable size="xl" backdrop="static">
        <ModalHeader toggle={toggle}><b>{SiteName}</b> Impact Rating Table</ModalHeader>
        <ModalBody>

          <table className="table table-bordered table-sm text-tiny">
            <thead className="bg-secondary">
              <tr>
                <th rowSpan="2">Level</th>
                <th rowSpan="2">Impact</th>
                {/*<th rowSpan="2">Colour</th>*/}
                <th colSpan="2" className="text-center">Impact</th>
                <th colSpan={evaluationPerspectives?.length} className="text-center">Impact description per evaluation perspective</th>
              </tr>
              <tr>

                <th className="text-center">Range From<div className="text-small" style={{ width: "90px" }}>(Incl.)</div></th>
                <th className="text-center">Range To <div className="text-small" style={{ width: "90px" }}>(Below)</div></th>

                {evaluationPerspectives?.map((item) => (
                  <th key={item.Id}>
                    { item.Title }
                  </th>
                ))}

              </tr>
            </thead>
            <tbody>

              {ratingTable?.map((item) => (
                <tr key={item.LevelNumber}>
                  <td>{item.LevelNumber}.</td>
                  <td>{item.ImpactBandName}</td>
                  {/*<td><div className="colour-block" style={{ backgroundColor: item.ImpactBandColour }}></div></td>*/}
                  <td className="text-end">
                    {item.ImpactFrom.toNumberString()}
                  </td>
                  <td className="text-end">
                    {renderMaximum(item.LevelNumber, item.ImpactTo)}
                  </td>
                  {evaluationPerspectives?.map((perspective) => (
                    <td key={perspective.Id} className="hover-edit">
                      <div style={{ width: "250px" }} dangerouslySetInnerHTML={getPerspectiveDescriptionWithDefault(item.LevelNumber, item.ImpactBandId, perspective.Id)} />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

        </ModalBody>

        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
          
        </ModalFooter>
      </Modal>


    </>
  );
};

export default ImpactTable;