import { getUserDetails } from '../../../helpers/authentication';
import { GetContrastColour, GetRatingBandId } from '../../../helpers/global';

const RiskValues = ({ RiskData, CompanyData, SiteData, RiskEvaluations, EventHandler }) => {

  const renderStatusBlock = (Title, EventMethod, OverallRating, TotalLikelyImpact) => {
    //console.log(Title);
    //console.log(TotalLikelyImpact);
    //console.log(RiskEvaluations.RiskImpactRatings);
    const bandId =GetRatingBandId(TotalLikelyImpact, RiskEvaluations.RiskImpactRatings);

    if (bandId >= 0) {
      const band = RiskEvaluations.RiskImpactRatings[bandId];
      return (
        <div onClick={() => EventHandler(EventMethod)} style={{ cursor: "pointer" }} className='risk-box'>
          <small>{Title}</small>
          {OverallRating ? <div style={{ backgroundColor: band.ImpactBandColour, color: GetContrastColour(band.ImpactBandColour) }}>{OverallRating + " - " + band.ImpactBandName} <br />{getUserDetails().Currency + " " + TotalLikelyImpact.toNumberString()}</div>
            : <div>Not Yet Started</div>}
        </div>
      )
    }
    else {
      return (
      <div onClick={() => EventHandler(EventMethod)} style={{ cursor: "pointer" }} className='risk-box'>
          <small>{Title}</small>
          {OverallRating ? <div>{"Below minumum threshold"} <br />{getUserDetails().Currency + " " + TotalLikelyImpact.toNumberString()}</div>
          : <div>Not Yet Started</div>}
        </div>
      )
    }
  }

  return (
    <>
      <div className='d-flex justify-content-around align-items-center flex-wrap'>
        <div className='d-flex text-center risk-header mb-3 col-md'>
          {RiskEvaluations ? <>
            {CompanyData.PotentialRiskUsed === 1 ? renderStatusBlock("POTENTIAL", "potential", RiskEvaluations.PotentialData.OverallRating, RiskEvaluations.PotentialData.TotalLikelyImpact) : ""}
            {renderStatusBlock("ACTUAL", "actual", RiskEvaluations.ActualData.OverallRating, RiskEvaluations.ActualData.TotalLikelyImpact)}
            {renderStatusBlock("TARGET", "target", RiskEvaluations.TargetData.OverallRating, RiskEvaluations.TargetData.TotalLikelyImpact)}
          </> : ""
          }
        </div>
      </div>
    </>
  );
};

export default RiskValues;