import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAuthToken } from "../../../helpers/authentication";


const UpdateStatus = ({ Title, StatusData, UpdateHandler }) => {
  const [note, setNote] = useState('');
  const [percentage, setPercentage] = useState(StatusData?.Percentage);
  const [editModal, setEditModal] = useState(false);
  const toggle = () => setEditModal(!editModal);

  const saveItem = async (event) => {
    event.stopPropagation();
    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: StatusData.Id, RiskId: StatusData.RiskId, ControlId: StatusData.ControlId, Percentage: percentage, Note: note }
    console.log(data);
    //console.log(StatusPath);
    //if (!StatusPath) {
    //  StatusPath = "/api/riskevaluation/updatestatus";
    //  //StatusData?.Percentage = percentage;
    //}
    try {
      const response = await fetch("api/controlassurancestatus", {
        method: 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        setNote("");
        if (UpdateHandler)
          UpdateHandler();
        ToastUtility.show({
          title: Title + ' Status', content: 'The status was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
      
      ToastUtility.show({
        title: Title + ' Status', content: 'There was an error saving the status!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }

    toggle();
  }




  return (
    <>

      <button className='btn btn-sm btn-info me-2' onClick={() => toggle() }><i className="far fa-edit me-2"></i>Update Status</button>

      <Modal isOpen={editModal} toggle={toggle} scrollable size="md" backdrop="static" >
        <ModalHeader toggle={toggle}>{Title}</ModalHeader>
        <ModalBody>
          <div className='row'>
            <div className='mb-3 col-md-4'>
              <NumericTextBoxComponent id='tbRiskAnalysis' name='tbRiskAnalysis' format="##0" min={0} max={100} step={1} placeholder='Percentage' floatLabelType='Always' showClearButton={true} value={percentage} onChange={e => setPercentage(e.target.value)} /> <div id='tbRiskAnalysisError' className='error-message' />
            </div>
            <div className='mb-3 col-12'>
              <TextBoxComponent multiline id='tbRiskAnalysisComment' name='tbRiskAnalysisComment' placeholder='Comment' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={note} onChange={e => setNote(e.value)} /> <div id='tbRiskAnalysisCommentError' className='error-message' />
            </div>

            {StatusData?.RiskId ? <>
              <h5 className="col-12 border-bottom">Previous Engagement</h5>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbPreviousPercentage' name='tbPreviousPercentage' placeholder='Previous Percentage' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={StatusData?.Percentage} />
              </div>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbModifiedBy' name='tbModifiedBy' placeholder='Modified By' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={StatusData?.UserName} />
              </div>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbPreviousDate' name='tbPreviousDate' placeholder='Modified Date' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={StatusData?.DateModified} />
              </div>
              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbPreviousComment' name='tbPreviousComment' placeholder='Previous Comment' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={StatusData?.Note} />
              </div>
            </> : ""}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal >

    </>

  )
};
export default UpdateStatus;