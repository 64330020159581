/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import Loader from '../Common/Loader';
import ActualRiskRating from './Actual/ActualRiskRating';
import AnalyseRisk from './Analyse/AnalyseRisk';
import RiskStatus from './Common/RiskStatus';
import RiskSummary from './Common/RiskSummary';
import RiskValues from './Common/RiskValues';
import EvaluateRisk from './Evaluation/EvaluateRisk';
import PlanRisk from './Plan/PlanRisk';
import PotentialRiskRating from './Potential/PotentialRiskRating';
import TargetRiskRating from './Target/TargetRiskRating';
import TreatRisk from './Treat/TreatRisk';

export default function RiskEditor(props) {

  const [riskData, setRiskData] = useState();
  const [siteData, setSiteData] = useState();
  const [companyData, setCompanyData] = useState();

  const [riskAnalysisStatusData, setRiskAnalysisStatusData] = useState();
  const [riskEvaluateStatusData, setRiskEvaluateStatusData] = useState();
  const [riskTreatStatusData, setRiskTreatStatusData] = useState();
  const [riskPlanStatusData, setRiskPlanStatusData] = useState();
  const [riskEvaluationData, setRiskEvaluationData] = useState();
  const [riskStep, setRiskStep] = useState();
  const [userList, setUserList] = useState();

  const [riskStatus, setRiskStatus] = useState();
  const [rootCauses, setRootCauses] = useState();
  const [controls, setControls] = useState();
  const [consequences, setConsequences] = useState();
  const [potentialActualTargetData, setPotentialActualTargetData] = useState();

  const [riskId, setRiskId] = useState('');
  const [isLoading, setIsLoading] = useState(true);


  const loadRisk = async (loadAssociatedData) => {
    setIsLoading(true);
    var bearer = 'Bearer ' + getAuthToken();
    const parts = window.location.href.split("/");
    const riskNumber = parts[parts.length - 1];
    setRiskId(riskNumber);
    //const userData = getUserDetails();
    try {
      const response = await fetch(`api/risks/getbyriskid/${riskNumber}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log("risk data: ", data);
        await setRiskData(data);
        if (loadAssociatedData)
          loadRiskData(data);
        else
          setIsLoading(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const loadRiskData = async (riskData) => {
    const userData = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();
    let loadCounter = 8;

    //Load Site Data
    try {
      const response = await fetch(`api/sitestructures/${riskData.SiteStructureId}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log("Site Data: ", data);
        //console.log(riskData);
        setSiteData(data);
        console.log(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Company Data
    try {
      const response = await fetch(`api/companies/${userData.CompanyId}`, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        setCompanyData(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      } else {
        console.error(response.status + ": " + response.statusText);

      }
    } catch (e) {
      console.error(e);
    }

    //Load Risk Evaluations
    try {
      const response = await fetch(`api/riskevaluation/GetRiskEvaluations/${userData.CompanyId}/${riskData.Id}/${userData.UserRole}`, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        setPotentialActualTargetData(data)
        loadCounter--;
        setIsLoading(loadCounter > 0);
      } else {
        console.error(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

    //Load Risk Status
    try {
      const response = await fetch(`api/riskstatuses/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskStatus(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

    //Load Risk Evaluations
    try {
      const response = await fetch(`api/riskevaluation/GetRiskEvaluations/${userData.CompanyId}/${riskData.Id}/${userData.UserRole}`, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setRiskEvaluationData(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      } else {
        console.error(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

    //Load Company Users
    try {
      const response = await fetch(`api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setUserList(data.map(item => ({
          ...item,
          FullName: item.FirstName + " " + item.LastName
        })));
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);

      }

    } catch (e) {
      console.error(e);
    }

    if (loadRiskStageStatus(riskData)) {
      loadCounter--;
      setIsLoading(loadCounter > 0);
    }

    if (loadRiskDecomposition(riskData)) {
      loadCounter--;
      setIsLoading(loadCounter > 0);
    }
  }

  const loadRiskStageStatus = async (riskData) => {
    const userData = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();
    let loadCounter = 4;

    //Load Risk Analysis Status
    try {
      const response = await fetch(`api/riskanalysisstatus/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskAnalysisStatusData(data);
        loadCounter--;
        if (loadCounter <= 0)
          return true;
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Risk Evaluate Status
    try {
      const response = await fetch(`api/riskevaluatestatus/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskEvaluateStatusData(data)
        loadCounter--;
        if (loadCounter <= 0)
          return true;
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Risk Treat status
    try {
      const response = await fetch(`api/risktreatstatus/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskTreatStatusData(data);
        loadCounter--;
        if (loadCounter <= 0)
          return true;
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Risk Plan status
    try {
      const response = await fetch(`api/riskplanstatus/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskPlanStatusData(data);
        //console.log(data)
        loadCounter--;
        if (loadCounter <= 0)
          return true;
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }
  }

  const loadRiskDecomposition = async (riskData) => {
    const userData = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();
    let loadCounter = 3;
    //Load root causes
    //console.log("loadRiskDecomposition", riskData)
    try {
      const response = await fetch(`api/rootcauses/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        // console.log(data)

        setRootCauses(data);
        loadCounter--;
        if (loadCounter <= 0)
          return true;
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Controls
    try {
      const response = await fetch(`api/controls/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        //console.log(data);
        data = data.map(item => ({ ...item, Assurances: JSON.parse(item.Assurances), ConnectedRootCauses: item.ConnectedRootCauses ? JSON.parse(item.ConnectedRootCauses) : [], ConnectedConsequences: item.ConnectedConsequences ? JSON.parse(item.ConnectedConsequences) : [] }))
        //console.log(data);
        //this.setState({ ControlsList: data });
        setControls(data);
        loadCounter--;
        if (loadCounter <= 0)
          return true;
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Consequences
    try {
      const response = await fetch(`api/consequences/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        //console.log(data)
        data = data.map(item => ({ ...item, ConnectedPerspectives: item.ConnectedPerspectives ? JSON.parse(item.ConnectedPerspectives) : [] }));
        //console.log(data);

        setConsequences(data);
        loadCounter--;
        if (loadCounter <= 0)
          return true;
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    loadRisk(true);
  }, []);


  const eventHandler = (data) => {
    setRiskStep(data);
    //console.log(data);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 "><h1>Risk Engagement</h1></div>
        <div className="col-md-6 text-end align-self-center">
          <Link to="/my-items" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Risks</Link>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <RiskSummary RiskData={riskData} />
          <RiskValues RiskData={riskData} SiteData={siteData} CompanyData={companyData} EventHandler={eventHandler} RiskEvaluations={riskEvaluationData} />
          <RiskStatus RiskData={riskData} RiskAnalysisStatusData={riskAnalysisStatusData} RiskEvaluateStatusData={riskEvaluateStatusData} RiskTreatStatusData={riskTreatStatusData} RiskPlanStatusData={riskPlanStatusData} EventHandler={eventHandler} />

          <PotentialRiskRating RiskData={riskData} SiteData={siteData} ControlsData={controls} RiskEvaluateStatusData={riskEvaluateStatusData} RootCauseData={rootCauses} ConsequenceData={consequences} PotentialData={potentialActualTargetData.PotentialData} RiskImpactRatings={potentialActualTargetData.RiskImpactRatings} IsVisible={riskStep === "potential"} OnStatusUpdate={() => loadRiskData(riskData)} />
          <ActualRiskRating RiskData={riskData} SiteData={siteData} ControlsData={controls} RiskEvaluateStatusData={riskEvaluateStatusData} RootCauseData={rootCauses} ConsequenceData={consequences} ActualData={potentialActualTargetData.ActualData} RiskImpactRatings={potentialActualTargetData.RiskImpactRatings} IsVisible={riskStep === "actual"} OnStatusUpdate={() => loadRiskData(riskData)} />
          <TargetRiskRating RiskData={riskData} SiteData={siteData} ControlsData={controls} RiskEvaluateStatusData={riskEvaluateStatusData} RootCauseData={rootCauses} ConsequenceData={consequences} TargetData={potentialActualTargetData.TargetData} RiskImpactRatings={potentialActualTargetData.RiskImpactRatings} IsVisible={riskStep === "target"} OnStatusUpdate={() => loadRiskData(riskData)} />

          <AnalyseRisk RiskId={riskData.Id} RiskData={riskData} IsVisible={riskStep === "analyse"} RootCauseData={rootCauses} ConsequenceData={consequences} ControlsData={controls} SiteData={siteData} UserList={userList} EventHandler={() => loadRisk(false)} OnDecompositionReload={() => loadRiskDecomposition(riskData)} OnStatusUpdate={() => loadRiskStageStatus(riskData)} />
          <EvaluateRisk RiskId={riskData.Id} IsVisible={riskStep === "evaluate"} RiskData={riskData} SiteData={siteData} RiskEvaluations={riskEvaluationData} EventHandler={eventHandler} OnStatusUpdate={() => loadRiskStageStatus(riskData)} />
          <TreatRisk RiskId={riskData.Id} UserList={userList} IsVisible={riskStep === "treat"} OnStatusUpdate={() => loadRiskStageStatus(riskData)} />
          <PlanRisk RiskId={riskData.Id} IsVisible={riskStep === "plan"} ControlsData={controls} OnStatusUpdate={() => loadRiskStageStatus(riskData)} />

        </>
      )}

    </>
  );
}

