import { useEffect, useState } from 'react';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import RiskStatusTitle from '../Common/RiskStatusTitle';
import BusinessObjectives from './BusinessObjectives';
import BusinessProcesses from './BusinessProcesses';
import Capitals from './Capitals';
import RootCauses from './RootCauses';
import Consequences from './Consequences';
import Stakeholders from './Stakeholders';
import Controls from './Controls';


const AnalyseRisk = ({ RiskId, RiskData, RootCauseData, ConsequenceData, ControlsData, SiteData, IsVisible, UserList, EventHandler, OnDecompositionReload, OnStatusUpdate }) => {

  const [editData, setEditData] = useState();


  const loadData = async () => {
    const User = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch(`api/riskanalysisstatus/getbyriskid/${RiskId}/${User.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setEditData(data);
        if (OnStatusUpdate)
          OnStatusUpdate();
        //console.log("Loading - ", data)
      }
      else {
        console.error(response.status + ": " + response.statusText);
        //if (response.status === 401)
        //  handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

  }


  useEffect(() => {
    loadData();
  }, []);



  if (IsVisible)
    return (

      <>
        
        <RiskStatusTitle RiskId={RiskId} Title='Analyse the Risk' StatusPath='api/riskanalysisstatus' UpdateHandler={loadData} StatusData={editData} HintText={`What is this screen about?\n\n"Analyse the Risk" is all about ARTICULATING the Risk ... making sure that we have documented and categorised it fully.\nThis allows us to then measure it more accurately and to better create gap-closing actions that will reduce the risk.\n\nIt involves the following:\n1. Linking the risk to those aspects that the risk impacts. This means identifying which specific:\n   - Business Objectives;\n   - Business Processes;\n   - Capitals; &\n   - Stakeholders;\n2. Identifying all the root causes of the risk and listing these.\n3. Identifying all the consequences of the risk and listing these.\n4. Identifying all the existing controls that are in place. Each control may be linked to its associated root causes and\n   consequences (this is the classical bow-tie thinking).\n\nWhen the above steps have been completed, then you will have completed this phase.\n\nHow do I engage with this screen?\nClick on the various 'engage' buttons to take you to the screen that lets you link the various things and create/amend controls.\n\nPlease keep the phase completion status up to date...\n\nThe phase completion status is shown above the "Analyse the Risk" button (green bar for completed, orange bar for in progress and red bar for Not Yet Started).\nThe date that the status was updated will be shown in that status bar.\nPlease update this status periodically - by clicking on the 'update' button.\n\nIn that way any user immediately sees the correct completion status at a glance.`} />

        <div className='card mb-3'>
          <div className='text-center'>
            <h6 className='my-3 fw-bold'>Risk Categorisation</h6>
          </div>
          <div className='card-body mb-4'>
            <div className="blue-cards">
              <div className="row ">
                <div className="col-md-3">
                  <BusinessObjectives RiskId={RiskId} ObjectiveData={RiskData.RiskBusinessObjectiveData ? JSON.parse(RiskData.RiskBusinessObjectiveData) : null} EventHandler={EventHandler} />
                </div>
                <div className="col-md-3">
                  <BusinessProcesses RiskId={RiskId} ProcessData={RiskData.RiskBusinessProcessData ? JSON.parse(RiskData.RiskBusinessProcessData) : null} EventHandler={EventHandler} />
                </div>
                <div className="col-md-3 ">
                  <Capitals RiskId={RiskId} CapitalData={RiskData.RiskCapitalData ? JSON.parse(RiskData.RiskCapitalData) : null} EventHandler={EventHandler} />
                </div>
                <div className="col-md-3">
                  <Stakeholders RiskId={RiskId} StakeholderData={RiskData.RiskStakeholderData ? JSON.parse(RiskData.RiskStakeholderData) : null} EventHandler={EventHandler} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card mb-3'>
          <div className='text-center'>
            <h6 className='my-3 fw-bold'>Risk Decomposition</h6>
          </div>
          <div className="card-body  mb-4">
            <div className="blue-cards">
              <div className='row '>
                <div className="col-md-4 mb-5">
                  <RootCauses RiskId={RiskId} RootCausesData={RootCauseData} ConsequenceData={ConsequenceData} ControlsData={ControlsData} EventHandler={EventHandler} ReloadHandler={OnDecompositionReload} />
                  
                </div>
                <div className="col-md-4 mb-5">
                  <Consequences RiskId={RiskId} RootCausesData={RootCauseData} ConsequenceData={ConsequenceData} ControlsData={ControlsData} SiteData={SiteData} EventHandler={EventHandler} ReloadHandler={OnDecompositionReload} />
                </div>
                <div className="col-md-12">
                  <Controls RiskId={RiskId} RootCausesData={RootCauseData} ConsequenceData={ConsequenceData} ControlsData={ControlsData} UserList={UserList} EventHandler={EventHandler} ReloadHandler={OnDecompositionReload} />
                </div>
                
              </div>
            </div>
          </div>
        </div>


      </>
    );
};


export default AnalyseRisk;