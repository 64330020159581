import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { CalculateLinearAmount, CalculateLinearStep, CalculateOverallRating } from '../../../helpers/Calculations';
import { GetContrastColour, GetProbabilityValue, GetRatingBandDetail } from '../../../helpers/global';
import ImpactTable from "./ImpactTable";

const RiskRatingTable = ({ RiskData, SiteData, EvaluationType, CurrentData, RatingImpactData, UpdateHandler }) => {

  const [probabilityRating, setProbabilityRating] = useState(0); //change this passed paea,
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [editId, setEditId] = useState();
  const [editData, setEditData] = useState();
  //const [ratingImpactData, setRatingImpactData] = useState();
  const [totalLikelyImpact, setTotalLikelyImpact] = useState();
  const [totalCashflow, setTotalCashflow] = useState();
  const [overallRating, setOverallRating] = useState();
  const [TLIBand, setTLIBand] = useState();
  const [TLIBandColour, setTLIBandColour] = useState();
  const [targetedCompletionDate, setTargetedCompletionDate] = useState();

  const saveEvaluation = async () => {
    var bearer = 'Bearer ' + getAuthToken();

    const data = {
      Id: editId,
      RiskId: RiskData.Id,
      ProbabilityRating: probabilityRating,
      OverallRating: overallRating,
      TotalLikelyImpact: totalLikelyImpact,
      MetaData: editData,
      TargetedCompletionDate:targetedCompletionDate,
    }
    //console.log(data);
    let path = "";
    switch (EvaluationType) {
      case "actual":
        path = "/api/riskevaluation/SaveActualData";
        break;
      case "target":
        path = "/api/riskevaluation/SaveTargetData";
        break;
      default:
        path = "/api/riskevaluation/SavePotentialData";
        break;
    }

    const response = await fetch(path, {
      method: "POST",
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      if (UpdateHandler)
        UpdateHandler();
      ToastUtility.show({
        title: EvaluationType.capitaliseFirstLetter() + ' Evaluation', content: 'The evaluation was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
      });
    } else {
      console.log(response.status + ": ", response.statusText);
      if (response.status === 401)
        window.location.href = "/login";
      else
        ToastUtility.show({
          title: EvaluationType.capitaliseFirstLetter() + ' Evaluation', content: 'There was an error saving the evaluation!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
    }
  }

  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // Trigger the onChange event manually when "Enter" is pressed
      e.target.blur(); // Trigger the change event by removing focus (if required)
    }
  };

  useEffect(() => {
    //console.log(CurrentData);
    const p = CurrentData.ProbabilityRating / 100;
    let update = CurrentData.MetaData;
    for (var i = 0; i < update.length; i++) {
      update[i].LikelyImpact = calculateLikelyImpact(update[i].Rating, p);
    
    }
    setTargetedCompletionDate(CurrentData.TargetedCompletionDate ? CurrentData.TargetedCompletionDate : null);
    setEditData(update);
    setEditId(CurrentData.Id);
    setProbabilityRating(CurrentData.ProbabilityRating);
    
    setTotalCashflow(update.reduce((total, item) => total + item.FinComponent, 0));
    const impact = update.reduce((total, item) => total + item.LikelyImpact, 0);
    const totalBand = GetRatingBandDetail(impact, RatingImpactData);
    setTotalLikelyImpact(impact);
    setTLIBand(totalBand.ImpactBandName);
    setTLIBandColour(totalBand.ImpactBandColour);
    //setOverallRating(CalculateOverallRating(update, SiteData?.ImmaterialityLevel, SiteData?.CatastrophicLevel, SiteData?.NumOfLevels, SiteData?.TypeId, impact));
    const step = CalculateLinearStep(0, SiteData?.CatastrophicLevel, SiteData?.NumOfLevels);
    setOverallRating(CalculateOverallRating(0, step, impact));
    //console.log(update);
    //console.log(CurrentData);
    //console.log(EvaluationType);
  }, [CurrentData]);

  const P = GetProbabilityValue(probabilityRating);
   

  const calculateImpact = (impactLevel) => {
    //const factor = (SiteData.CatastrophicLevel) / (SiteData.NumOfLevels - 1);
    //const factor = (SiteData.CatastrophicLevel - SiteData.ImmaterialityLevel) / (SiteData.NumOfLevels - 2);
    //let result = (factor * (impactLevel - 2)) + SiteData.ImmaterialityLevel;
    //let result = factor * impactLevel;
    const step = CalculateLinearStep(0, SiteData.CatastrophicLevel, SiteData.NumOfLevels);
    let result = CalculateLinearAmount(0, step, impactLevel);
    console.log(step, impactLevel, SiteData.CatastrophicLevel, SiteData.NumOfLevels);
    if (result < 0)
      result = 0;
    return result;
  }
    
  const calculateLikelyImpact = (impactLevel, percentage) => {
    let result = calculateImpact(impactLevel) * percentage;
    return result;
  }

  const setCashFlow = (perspectiveId, value) => {
    const update = editData.map(item =>
      item.PerspectiveId === perspectiveId
        ? { ...item, FinComponent: value }
        : item
    );

    setEditData(update);
    setTotalCashflow(update.reduce((total, item) => total + item.FinComponent, 0));
  }

  const updateProbability = (value) => {
    //console.log()
    const p = value / 100;
    let update = editData;
    for (var i = 0; i < update.length; i++) {
      update[i].LikelyImpact = calculateLikelyImpact(update[i].Rating, p);

    }
    setEditData(update);
    
    setProbabilityRating(value);
    setTotalCashflow(update.reduce((total, item) => total + item.FinComponent, 0));
    const impact = update.reduce((total, item) => total + item.LikelyImpact, 0);
    const totalBand = GetRatingBandDetail(impact, RatingImpactData);
    setTotalLikelyImpact(impact);
    setTLIBand(totalBand.ImpactBandName);
    setTLIBandColour(totalBand.ImpactBandColour);
    //setOverallRating(CalculateOverallRating(update, SiteData?.ImmaterialityLevel, SiteData?.CatastrophicLevel, SiteData?.NumOfLevels, SiteData?.TypeId, impact));
    const step = CalculateLinearStep(0, SiteData?.CatastrophicLevel, SiteData?.NumOfLevels);
    setOverallRating(CalculateOverallRating(0, step, impact));
  }

  const setImpactLevel = (perspectiveId, value) => {
    const update = editData.map(item =>
      item.PerspectiveId === perspectiveId
        ? { ...item, Rating: value, LikelyImpact: calculateLikelyImpact(value, P) }
        : item
    );
    setEditData(update);
    const impact = update.reduce((total, item) => total + item.LikelyImpact, 0);
    const totalBand = GetRatingBandDetail(impact, RatingImpactData);
    setTotalLikelyImpact(impact);
    setTLIBand(totalBand.ImpactBandName);
    setTLIBandColour(totalBand.ImpactBandColour);
    //setOverallRating(CalculateOverallRating(update, SiteData?.ImmaterialityLevel, SiteData?.CatastrophicLevel, SiteData?.NumOfLevels, SiteData?.TypeId, impact));
    const step = CalculateLinearStep(0, SiteData?.CatastrophicLevel, SiteData?.NumOfLevels);
    setOverallRating(CalculateOverallRating(0, step, impact));
  }


  return (
    <>

      <Card className="my-4">
        <CardBody>
          
          <div className="row mb-3">
            <div className="col-7 align-self-end">
              <ImpactTable SiteStructureId={SiteData.Id} SiteName={SiteData.Name} />
            </div>
            
            <div className='col-3 '>
              {EvaluationType?.toLowerCase() === 'target' ?
                <>
                  <label className='fw-bold'>Targeted Completion Date</label>
                  <DatePickerComponent placeholder='Targeted Completion Date' floatLabelType='Never' format='yyyy-MM-dd' min={new Date()} value={targetedCompletionDate} onChange={(e) => { setTargetedCompletionDate(e.value ) }} />
                </>
                : <> </>}
            </div>
            <div className="col-2">
              <label className='fw-bold'>Probability Rating <small>(%)</small>
                <span id="errProbabilityRating" className='ErrorText'></span>
              </label>
              
              <NumericTextBoxComponent disabled={isReadOnly} showSpinButton={!isReadOnly} onKeyDown={handleKeyDown} format="##0" max={100} min={0} step={1} change={(e) => updateProbability(e.value) } className='text-end me-2' value={probabilityRating} />
            </div>
          </div>
          <table className='table table-sm table-striped risk-rating-table'>
            <thead>
              <tr className='fw-bold'>
                <th style={{ width: "22%" }} className="fw-bold">Rating Perspective</th>
                <th style={{ width: "13%" }} className='text-center fw-bold'>Impact Score</th>
                <th style={{ width: "10%" }} className='text-center fw-bold'>Impact Band</th>
                <th style={{ width: "10%" }} className='text-end fw-bold'>Impact (I)</th>
                <th style={{ width: "8%" }} className='text-center fw-bold'>P</th>
                <th style={{ width: "12%" }} className='text-end fw-bold'>(I * P)<br /> Likely Impact</th>
                <th style={{ width: "10%" }} className='text-center fw-bold'>Likely<br />Impact Band</th>
                <th style={{ width: "15%" }} className='text-end fw-bold'>Cash Flow Component</th>
              </tr>
            </thead>
            <tbody>
              {editData?.sort((a, b) => a.DisplayOrder - b.DisplayOrder).map(item => {
                
                const RatingBand = GetRatingBandDetail(calculateImpact(item.Rating), RatingImpactData);
                const LikelyBand = GetRatingBandDetail(item.LikelyImpact, RatingImpactData);
                return (<tr key={item.PerspectiveId}>
                  <td>{item.PerspectiveName}</td>
                  <td className="text-center">
                    <NumericTextBoxComponent className='text-end me-2' format='n1' showSpinButton={true} min={0} step={0.1} value={item.Rating} change={e => setImpactLevel(item.PerspectiveId, e.value)} />
                  </td>
                  <td className="text-center" style={{ backgroundColor: RatingBand.ImpactBandColour, color: GetContrastColour(RatingBand.ImpactBandColour) }}>{RatingBand.ImpactBandName}</td>
                  <td className="text-end">{getUserDetails().Currency + " " + calculateImpact(item.Rating).toNumberString()} </td>
                  
                  <td className="text-center">{P.toPercentage()}</td>
                  <td className="text-end">{getUserDetails().Currency + " " + item.LikelyImpact.toNumberString()}</td>
                  <td className="text-center" style={{ backgroundColor: LikelyBand?.ImpactBandColour, color: GetContrastColour(LikelyBand?.ImpactBandColour) }}>{LikelyBand?.ImpactBandName}</td>
                  <td><NumericTextBoxComponent className='text-end me-2' format='n0' disabled={false} showSpinButton={true} min={0} value={item.FinComponent} change={e => setCashFlow(item.PerspectiveId, e.value)} /></td>
                </tr>)
              })}
            </tbody>
            <tfoot>
              <tr>
                <td className='text-end fw-bold' colSpan="5">Total Likely Impact (TLI): </td>
                <td className='text-end fw-bold'>{getUserDetails().Currency + " " + totalLikelyImpact?.toNumberString()}</td>
                <td></td>
                <td className='text-end fw-bold'>{getUserDetails().Currency + " " + totalCashflow?.toNumberString()}</td>
              </tr>
              <tr>
                <td className='text-end fw-bold' colSpan="5">TLI Score: </td>
                <td className='text-end fw-bold'>{overallRating}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className='text-end fw-bold' colSpan="5">TLI Band: </td>
                <td className='text-end fw-bold' style={{ backgroundColor: TLIBandColour, color: GetContrastColour(TLIBandColour)  }}>{TLIBand}</td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
          
          
        </CardBody>
        <CardFooter className="text-end">
          <Button color='success' size="sm" onClick={saveEvaluation}>Save<i className="far fa-check-circle ms-2"></i></Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default RiskRatingTable;