import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipComponent = ({ id, target, content }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip placement="top" isOpen={tooltipOpen} target={target} toggle={toggle}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Tooltip>
  );
};

export default TooltipComponent;
