export const CalculateLinearStep = (MinValue, MaxValue, NumSteps) => {
  return (MaxValue / (NumSteps - 1));
}

export const CalculateLinearAmount = (MinValue, StepValue, Score) => {
  return StepValue * Score;
}

export const CalculateOverallRating = (MinValue, StepValue, TotalAmount) => {
  return (TotalAmount / StepValue).toFixed(1); 
  //console.log(data)
  //let scale = (cv / iv) ** (1 / nl - 2);
  ////let result = 0;
  //if (data) {


  //  if (type === 1) {
  //    let totalRiskScore = (total - iv) / (((cv - iv) / (nl - 2))) + 2;
  //    return totalRiskScore.toFixed(1);
  //  }
  //  else {
  //    let totalRiskScore = (Math.log(total / iv) / Math.log(scale)) + 2;
  //    return totalRiskScore.toFixed(1);
  //  }
  //} else
  //  return 0;
}