import moment from "moment/moment";
import { useState } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { getUserDetails } from '../../../helpers/authentication';
import { CustomFixedDecimals, GetRatingBand } from "../../../helpers/global";


const RiskTypeDetail = ({ RiskId, BlockHeading, TypeData, RiskImpactRatings, Site, IsVisible, EventHandler }) => {

  const setCurrentView = (type) => {
  }

  const getStyleByCompleteStatus = (Status) => {
    let style = {
      //border: "1px solid #000",
      padding: "3px"
    };
    return style;
  }


  if (IsVisible)
    return (
      <>
        <div className="text-center">
          {(TypeData.Id > 0) ? (<small style={getStyleByCompleteStatus(TypeData.CompleteStatus)}> {TypeData.CompleteStatus}% - {moment(TypeData.DateModified).format("DD/MM/YY")} </small>) : (<small>Not Yet Started</small>)}
        </div>

        <Card className={" border-evaluation " + (BlockHeading.toLowerCase())}>
          <CardHeader className="text-center fw-bold">
            {BlockHeading}
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-4 text-center fw-bold">Risk Score</div>
              <div className="col-4 text-center fw-bold">Likely Impact</div>
              <div className="col-4 text-center fw-bold">Risk Level</div>
            </div>
            <hr className="py-1 my-1" />
            {
              TypeData && TypeData.MetaData.length > 0 &&
              TypeData.MetaData.map((item, idx) =>
                <div key={idx}>
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8 text-center align-self-center">{getUserDetails().Currency + " " + item.LikelyImpact.toNumberString()}</div>
                    <div className="col-2 text-end align-self-center"></div>
                  </div>
                </div>
              )
            }
            <hr className="py-1 my-1" />
            <div className="row align-items-center">
              <div className="col-3 text-center fw-bold">
                {CustomFixedDecimals(TypeData.OverallRating)}
              </div>
              <div className="col-6 text-center fw-bold">
                {getUserDetails().Currency + " " + TypeData.TotalLikelyImpact.toNumberString()}
              </div>
              <div className="col-3 text-center fw-bold">
                {TypeData.OverallRating > 0 ? GetRatingBand(TypeData.OverallRating, RiskImpactRatings, Site?.ImmaterialityLevel, Site?.CatastrophicLevel, Site?.NumOfLevels, Site?.TypeId, TypeData.TotalLikelyImpact) : 0}
              </div>
            </div>
            <div className="text-center">
              <hr />
              <button type="button" onClick={() => EventHandler(BlockHeading.toLowerCase())} className="btn btn-info"><i className="far fa-edit me-2"></i>Update Status</button>
            </div>
          </CardBody>
          
        </Card>
      </>
    );
};

export default RiskTypeDetail;