import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { useState, useEffect } from 'react';
import RiskRatingTable from "../Common/RiskRatingTable";
import Consequences from "../Views/Consequences";
import RootCauses from "../Views/RootCauses";
import RiskStatusTitle from '../Common/RiskStatusTitle';

const PotentialRiskRating = ({ RiskData, SiteData,  RootCauseData, ConsequenceData, ControlsData, RiskImpactRatings, PotentialData, IsVisible, OnStatusUpdate }) => {

  const [editData, setEditData] = useState();


  useEffect(() => {

    const status = {
      RiskId: RiskData.Id,
      Percentage: PotentialData.CompleteStatus,
      Note: PotentialData.Comment,
      DateModified: PotentialData.DateModified,
      UserName: PotentialData.UpdatedBy
    }
    setEditData(status);
  }, []);

  const loadData = async () => {
    if (OnStatusUpdate)
      OnStatusUpdate();
  }

 const OnEvaluationSave = async () => {
    
    ToastUtility.show({
      title: 'Save Risk Evaluation', content: 'Risk evaluation was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
    });
  }

  if (IsVisible)
    return (
      <>
        <RiskStatusTitle RiskId={RiskData.Id} Title='Potential Risk Rating' RiskType='potential' UpdateHandler={loadData} StatusData={editData} HintText={''} />
        
        <div className="row mt-4">
          <div className="col-md-4"><RootCauses RootCauseData={RootCauseData} ControlsData={ControlsData } /> </div>
          <div className="col-md-4"><Consequences ConsequenceData={ConsequenceData} ControlsData={ControlsData} /></div>
        </div>

        <RiskRatingTable RiskData={RiskData} SiteData={SiteData} CurrentData={PotentialData} RatingImpactData={RiskImpactRatings} EvaluationType="potential" UpdateHandler={loadData} />

      </>
    );
};

export default PotentialRiskRating;