/* eslint-disable no-extend-native */
Number.prototype.toTwoDecimalString = function () {
  return this.toFixed(2);
};

Number.prototype.toOrdinal = function () {
  const number = this;
  let suffix = '';

  const ones = number % 10;
  const tens = Math.floor(number / 10) % 10;

  if (tens === 1) {
    suffix = 'th';
  } else {
    switch (ones) {
      case 1:
        suffix = 'st';
        break;
      case 2:
        suffix = 'nd';
        break;
      case 3:
        suffix = 'rd';
        break;
      default:
        if (number !== 0)
          suffix = 'th';
        break;
    }
  }
  return `${number}${suffix}`;
};

Number.prototype.toNumericString = function () {
  const value = this;
  const options = {
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: value % 1 === 0 ? 0 : 2,
  };
  return value.toLocaleString('en-US', options);
};

Number.prototype.toPercentage = function (NumDecimals) {
  const value = this;
  
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: NumDecimals? NumDecimals : 0,
  };
  return (value*100).toLocaleString('fr-CH', options)+"%";
};

Number.prototype.toNumberString = function () {
  const value = this;
  const options = {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  return value.toLocaleString('fr-CH', options);
};

Number.prototype.in = function (...values) {
  return values.includes(this.valueOf());
};