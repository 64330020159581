import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent, DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails, setAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { CommandTemplate, SearchGridToolbar, ConfirmDialogStyles, GenerateSiteStructureTree } from '../../helpers/global';
import { fieldRequired } from '../../helpers/validation';

export class ItemsList extends Component {

  static contextType = Context;
  constructor(props) {
    super(props);
    const adminCommandTemplate = [
      { type: 'Engage', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-settings e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat text-danger', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], loading: true, editModal: false, viewModal: false, gridCommands: adminCommandTemplate, 
      Id: 0,
      CompanyId: 0,
      RiskNumber: '',
      Name: '',
      Description: '',
      RiskTypeId: 0,
      RiskCategoryId: 0,
      RiskStatusId: 0,
      SiteStructureId: 0,
      SpeedOfOnset: 0,
      ResponsiblePersonId: 0,
      ResponsibleTeamId: 0,
      HasOpportunities: false,
      Opportunities: '',
      HasIndicators: false,
      Indicators: '',
      DeactivationReason: '',
      DateAdded: new Date(),
      AddedBy: 0,
      AddedByTeamId: 0,
      fRiskTypeId: 0,
      fRiskCategoryId: 0,
      fRiskStatusId: -1,
      fSiteStructureId: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      CompanyList: [],
      RiskCategoryList: [],
      RiskStatusList: [],
      RiskTypeList: [],
      SiteStructureList: [],
      TeamList: [],
      UserList: [],
      SelectedItem: 0,

    };
    this.toggle = this.toggle.bind(this);
    this.viewToggle = this.viewToggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  viewToggle() {
    this.setState({
      viewModal: !this.state.viewModal
    });
  }

  componentDidMount() {
    document.title = "Risk List";
    this.loadData();
  }


  editItem = (id) => {
    const userData = getUserDetails();

    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      if (userData.UserRole > 9 || userData.Id === data.ResponsiblePersonId) {
        this.setState({ Id: data.Id, CompanyId: data.CompanyId, RiskNumber: data.RiskNumber, Name: data.Name, Description: data.Description, RiskTypeId: data.RiskTypeId, RiskCategoryId: data.RiskCategoryId, RiskStatusId: data.RiskStatusId, SiteStructureId: data.SiteStructureId, SpeedOfOnset: data.SpeedOfOnset, ResponsiblePersonId: data.ResponsiblePersonId, ResponsibleTeamId: data.ResponsibleTeamId, HasOpportunities: data.HasOpportunities, Opportunities: data.Opportunities, HasIndicators: data.HasIndicators, Indicators: data.Indicators, DeactivationReason: data.DeactivationReason, DateAdded: data.DateAdded, AddedBy: data.AddedBy, AddedByTeamId: data.AddedByTeamId, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, });
      } else {
        ToastUtility.show({
          title: 'Risks', content: 'You do not have permission to edit this risk!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }

    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        CompanyId: 0,
        RiskNumber: '',
        Name: '',
        Description: '',
        RiskTypeId: 0,
        RiskCategoryId: 0,
        RiskStatusId: 0,
        SiteStructureId: 0,
        SpeedOfOnset: 0,
        ResponsiblePersonId: 0,
        ResponsibleTeamId: 0,
        HasOpportunities: false,
        Opportunities: '',
        HasIndicators: false,
        Indicators: '',
        DeactivationReason: 'n/a',
        DateAdded: new Date(),
        AddedBy: 0,
        AddedByTeamId: 0,
        DateModified: new Date(),
        ModifiedBy: 0,
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Name, 'tbNameError', '* required');
    valid &= fieldRequired(this.state.Description, 'tbDescriptionError', '* required');
    valid &= fieldRequired(this.state.RiskTypeId, 'tbRiskTypeIdError', '* required');
    valid &= fieldRequired(this.state.RiskCategoryId, 'tbRiskCategoryIdError', '* required');
    valid &= fieldRequired(this.state.RiskStatusId, 'tbRiskStatusIdError', '* required');
    valid &= fieldRequired(this.state.SiteStructureId, 'tbSiteStructureIdError', '* required');
    valid &= fieldRequired(this.state.SpeedOfOnset, 'tbSpeedOfOnsetError', '* required');
    valid &= fieldRequired(this.state.ResponsiblePersonId, 'tbResponsiblePersonIdError', '* required');
    valid &= fieldRequired(this.state.ResponsibleTeamId, 'tbResponsibleTeamIdError', '* required');
    valid &= fieldRequired(this.state.AddedByTeamId, 'tbAddedByTeamIdError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.Id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          DateAdded: data.DateAdded,
          AddedBy: data.AddedBy,
          Id: data.Id,
          Status: data.Status
        });
      }
      else {
        this.setState({
          Status: 1
        });
      }
      this.setState({ editModal: false });
      this.saveData(this.state.editId);
    }
  }

  deleteItem = async (id) => {

    const result = await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  viewItem = (id) => {
    const { handleNavigate } = this.context;
    const userData = getUserDetails();
    //console.log(id)
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      //console.log(data);
      //return;
      if (userData.UserRole > 9 || userData.Id === data.ResponsiblePersonId) {
        //handleNavigate("/risk-edit/" + data.RiskNumber);
        handleNavigate("/risk-edit/" + data.RiskId);
      } else {
        ToastUtility.show({
          title: 'Risks', content: 'You do not have permission to view this risk!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }
    }
  };

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {

      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
      case 'Engage':
        this.viewItem(args.rowData.Id);
        break;
      case 'Edit':
        this.editItem(args.rowData.Id);
        break;
      default: //edit
        this.editItem(args.rowData.Id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={SearchGridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='RiskNumber' width='50' headerText="Risk #" />
          <ColumnDirective field='Name' width='80' headerText="Risk Name" />
          <ColumnDirective field='Description' width='100' headerText="Risk Description" />
          <ColumnDirective field='RiskTypeName' width='50' headerText="Type" />
          <ColumnDirective field='StatusName' width='60' headerText="Status" />
          <ColumnDirective field='RiskCategoryName' width='50' headerText="Category" />
          <ColumnDirective field='SiteStructureName' width='50' headerText="Site" />
          <ColumnDirective headerText='Actions' width='70' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {

    let siteStructureFilter = { dataSource: GenerateSiteStructureTree(this.state.SiteStructureList, true), value: 'id', text: 'name', parentValue: "pid", hasChildren: 'hasChild' };
    let siteStructureFields = { dataSource: GenerateSiteStructureTree(this.state.SiteStructureList, false), value: 'id', text: 'name', parentValue: "pid", hasChildren: 'hasChild' };


    let data = this.state.editData;

    if (this.state.fRiskTypeId !== 0) {
      data = data.filter(item => item.RiskTypeId === this.state.fRiskTypeId || (!this.state.fRiskTypeId && !item.RiskTypeId))
    }
    if (this.state.fRiskCategoryId !== 0) {
      data = data.filter(item => item.RiskCategoryId === this.state.fRiskCategoryId || (!this.state.fRiskCategoryId && !item.RiskCategoryId))
    }
    if (this.state.fRiskStatusId >= 0) {
      data = data.filter(item => item.Status === this.state.fRiskStatusId || (!this.state.fRiskStatusId && !item.Status))
    }
    if (parseInt(this.state.fSiteStructureId) !== 0) {
      data = data.filter(item => item.SiteStructureId === parseInt(this.state.fSiteStructureId) || (!this.state.fSiteStructureId && !item.SiteStructureId))
    }
    if (!this.state.loading && this.state.editData.length > 0)
      data = data.map(item => ({ ...item, StatusName: item.Status === 0 ? "Draft" : item.Status === 1 ? "Active" : "Deactivated" }))
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : ItemsList.renderDataTable(data, this.state.gridCommands, this.onGridCommand);
    //console.log(this.state)
    return (
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={6}>
              <h1>Risk List</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Dashboard</Link>
              <Button color="primary" size="sm" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='mb-4'>
              <div className='card card-body shadow mb-3'>
                <b>Filters</b>
                <div className='row'>
                  <div className='col-md-3'>
                    <DropDownListComponent id='ddFilterId' name='ddFilterId' placeholder='Risk Type' dataSource={[{ Title: "-All-", Id: 0 }, ...this.state.RiskTypeList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.fRiskTypeId} change={e => this.setState({ fRiskTypeId: e.itemData ? e.itemData.Id : 0 })} />
                  </div>
                  <div className='col-md-3'>
                    <DropDownListComponent id='ddCategoryFilterId' name='ddCategoryFilterId' placeholder='Risk Category' dataSource={[{ Title: "-All-", Id: 0 }, ...this.state.RiskCategoryList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.fRiskCategoryId} change={e => this.setState({ fRiskCategoryId: e.itemData ? e.itemData.Id : 0 })} />
                  </div>
                  <div className='col-md-3'>
                    <DropDownListComponent id='ddStatusFilterId' name='ddStatusFilterId' placeholder='Risk Status' dataSource={[{ Title: "-All-", Id: -1 }, { Title: "Draft", Id: 0 }, { Title: "Active", Id: 1 }, { Title: "Deactivated", Id: 2 }]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.fRiskStatusId} change={e => this.setState({ fRiskStatusId: e.itemData ? e.itemData.Id : 0 })} />
                  </div>
                  <div className='col-md-3'>
                    {/*<DropDownListComponent id='ddSiteStructureFilterId' name='ddSiteStructureFilterId' placeholder='Site Structure' showClearButton={true} dataSource={[{ Name: "-All-", Id: 0 }, ...this.state.SiteStructureList]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.fSiteStructureId} change={e => this.setState({ fSiteStructureId: e.itemData ? e.itemData.Id : 0 })} />*/}
                    <DropDownTreeComponent fields={siteStructureFilter} id='ddSiteStructureFilterId' name='ddSiteStructureFilterId' showClearButton={false} placeholder='Site' floatLabelType='Always' select={e => this.setState({ fSiteStructureId: e.itemData.id })} value={[this.state.fSiteStructureId]} />
                  </div>
                </div>
              </div>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Risk</ModalHeader>
          <ModalBody>

            <div className='row'>
              <div className='mb-3'>
                <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Name} onChange={e => this.setState({ Name: e.target.value })} /> <div id='tbNameError' className='error-message' />
              </div>

              <div className='mb-3'>
                <TextBoxComponent id='tbDescription' multiline name='tbDescription' placeholder='Description' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Description} onChange={e => this.setState({ Description: e.target.value })} /> <div id='tbDescriptionError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddRiskTypeId' name='ddRiskTypeId' placeholder='Risk Type' dataSource={[{ Title: "-SELECT-", Id: 0 }, ...this.state.RiskTypeList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.RiskTypeId} change={e => this.setState({ RiskTypeId: e.value })} /><div id='tbRiskTypeIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddRiskCategoryId' name='ddRiskCategoryId' placeholder='Risk Category' dataSource={[{ Title: "-SELECT-", Id: 0 }, ...this.state.RiskCategoryList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.RiskCategoryId} change={e => this.setState({ RiskCategoryId: e.value })} /><div id='tbRiskCategoryIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddRiskStatusId' name='ddRiskStatusId' placeholder='Risk Status' dataSource={[{ Title: "-SELECT-", Id: 0 }, ...this.state.RiskStatusList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.RiskStatusId} change={e => this.setState({ RiskStatusId: e.value })} /><div id='tbRiskStatusIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                {/*<DropDownListComponent id='ddSiteStructureId' name='ddSiteStructureId' placeholder='Site Structure' dataSource={[{ Name: "-SELECT-", Id: 0 }, ...this.state.SiteStructureList]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.SiteStructureId} change={e => this.setState({ SiteStructureId: e.value })} />*/}
                <DropDownTreeComponent fields={siteStructureFields} id='ddSiteStructureId' name='ddSiteStructureId' showClearButton={false} placeholder='Site' floatLabelType='Always' select={e => this.setState({ SiteStructureId: e.itemData.id })} value={[this.state.SiteStructureId]} />
                <div id='tbSiteStructureIdError' className='error-message' />
              </div>
            </div>

            <div className='row'>
              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddResponsiblePersonId' name='ddResponsiblePersonId' placeholder='Responsible Person' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...this.state.UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.ResponsiblePersonId} change={e => this.setState({ ResponsiblePersonId: e.value })} /><div id='tbResponsiblePersonIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddResponsibleTeamId' name='ddResponsibleTeamId' placeholder='Responsible Team' dataSource={[{ Name: "-SELECT-", Id: 0 }, ...this.state.TeamList]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.ResponsibleTeamId} change={e => this.setState({ ResponsibleTeamId: e.value })} /><div id='tbResponsibleTeamIdError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <CheckBoxComponent id='cbHasOpportunities' name='cbHasOpportunities' type='checkbox' label='Has Opportunities' checked={this.state.HasOpportunities} change={e => this.setState({ HasOpportunities: e.checked })} /> &nbsp; <div id='cbHasOpportunitiesError' className='error-message' />
              </div>

              <div className='mb-3 col-md-6'>
                <CheckBoxComponent id='cbHasIndicators' name='cbHasIndicators' type='checkbox' label='Has Indicators' checked={this.state.HasIndicators} change={e => this.setState({ HasIndicators: e.checked })} /> &nbsp; <div id='cbHasIndicatorsError' className='error-message' />
              </div>

              {this.state.HasOpportunities ? <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbOpportunities' name='tbOpportunities' multiline={true} placeholder='Opportunities' type='text' rows={5} maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Opportunities} onChange={e => this.setState({ Opportunities: e.target.value })} /> <div id='tbOpportunitiesError' className='error-message' />
              </div> : <div className='mb-3 col-md-6'></div>}

              {this.state.HasIndicators && <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbIndicators' name='tbIndicators' multiline={true} placeholder='Indicators' type='text' rows={5} maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Indicators} onChange={e => this.setState({ Indicators: e.target.value })} /> <div id='tbIndicatorsError' className='error-message' />
              </div>}
            </div>

            <div className='row'>
              <div className='mb-3 col-md-4'>
                <NumericTextBoxComponent id='tbSpeedOfOnset' name='tbSpeedOfOnset' placeholder='Speed Of Onset (Months)' min={1} step={1} format="n0" floatLabelType='Always' showClearButton={true} value={this.state.SpeedOfOnset} onChange={e => this.setState({ SpeedOfOnset: e.target.value })} /> <div id='tbSpeedOfOnsetError' className='error-message' />
              </div>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddAddedByTeamId' name='ddAddedByTeamId' placeholder='Added By Team' dataSource={[{ Name: "-SELECT-", Id: 0 }, ...this.state.TeamList]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.AddedByTeamId} change={e => this.setState({ AddedByTeamId: e.value })} /><div id='tbAddedByTeamIdError' className='error-message' />
              </div>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddStatusId' name='ddStatusId' placeholder='Status' dataSource={[{ Name: "Draft", Id: 0 }, { Name: "Active", Id: 1 }, { Name: "Deactivated", Id: 2 }]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.Status} change={e => this.setState({ Status: e.value })} /><div id='ddStatusIdError' className='error-message' />
              </div>
            </div>
            {this.state.editId > 0 && this.state.Status === 2 ? <div className='mb-3'>
              <TextBoxComponent id='tbDeactivationReason' name='tbDeactivationReason' placeholder='Deactivation Reason' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.DeactivationReason} onChange={e => this.setState({ DeactivationReason: e.target.value })} /> <div id='tbDeactivationReasonError' className='error-message' />
            </div> : ""}

          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();
    //console.log(userData)
    try {
      const response = await fetch(`api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data?.map(item => ({ ...item, FullName: `${item.FirstName} ${item.LastName}` }))
        this.setState({ UserList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/teams/getcompanyteams/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data.map(item => ({ ...item, SelectedUsers: JSON.parse(item.SelectedUsers) }))
        this.setState({ TeamList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/risktypes/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskTypeList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskcategories/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskCategoryList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskstatuses/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskStatusList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/sitestructures/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ SiteStructureList: data });
        //console.log(GenerateSiteStructureTree(data, true));
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/risks/getallcompanyrisks/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, showSuccess: false });
    const userData = getUserDetails();

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, CompanyId: userData.CompanyId, RiskNumber: this.state.RiskNumber, Name: this.state.Name, Description: this.state.Description, RiskTypeId: this.state.RiskTypeId, RiskCategoryId: this.state.RiskCategoryId, RiskStatusId: this.state.RiskStatusId, SiteStructureId: this.state.SiteStructureId, SpeedOfOnset: this.state.SpeedOfOnset, ResponsiblePersonId: this.state.ResponsiblePersonId, ResponsibleTeamId: this.state.ResponsibleTeamId, HasOpportunities: this.state.HasOpportunities, Opportunities: this.state.Opportunities, HasIndicators: this.state.HasIndicators, Indicators: this.state.Indicators, DeactivationReason: this.state.DeactivationReason, DateAdded: this.state.DateAdded, AddedBy: userData.Id, AddedByTeamId: this.state.AddedByTeamId, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, }

    try {
      const response = await fetch('api/risks', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        dataId === 0 && (userData.UserRole > 9 || userData.Id === this.state.ResponsiblePersonId) && handleNavigate("/risk-edit/" + data.Item2);
        this.loadData();
        ToastUtility.show({
          title: 'Risks', content: 'The risks was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risks', content: 'There was an error saving the risks!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/risks/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risks', content: 'The risks was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risks', content: 'There was an error deleting the risks!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}
