/* eslint-disable default-case */
import { useEffect, useState } from 'react';
import RiskRatingTable from "../Common/RiskRatingTable";
import RiskStatusTitle from '../Common/RiskStatusTitle';
import Consequences from "../Views/Consequences";
import Controls from "../Views/Controls";
import RootCauses from "../Views/RootCauses";


const ActualRiskRating = ({ RiskData, SiteData, ControlsData, RootCauseData, ConsequenceData, RiskImpactRatings, ActualData, IsVisible, OnStatusUpdate }) => {
  const [editData, setEditData] = useState();
  const [selectedCauses, setSelectedCauses] = useState([]);
  const [selectedControls, setSelectedControls] = useState([]);
  const [selectedConsequences, setSelectedConsequences] = useState([]);

  const HandleHighlight = (section, value) => {
    //console.log("HandleHighlight", section, value);
    switch (section) {
      case "causes":
        setSelectedCauses([value]);
        setSelectedConsequences([])
        const causecontrols = ControlsData
          .filter(control => control.ConnectedRootCauses.includes(value))
          .map(control => control.Id);
        
        setSelectedControls(causecontrols);

        break;
      case "consequences":
        setSelectedConsequences([value]);
        setSelectedCauses([]);
        const consequencecontrols = ControlsData
          .filter(control => control.ConnectedConsequences.includes(value))
          .map(control => control.Id);

        setSelectedControls(consequencecontrols);

        break;

      case "controls":
        setSelectedControls([value]);
        const selected = ControlsData.find(p => p.Id === value);
        setSelectedCauses(selected.ConnectedRootCauses)
        setSelectedConsequences(selected.ConnectedConsequences)
        break;
    }
  }

  const loadData = async () => {
    if (OnStatusUpdate)
      OnStatusUpdate();
}

  useEffect(() => {

    const status = {
      RiskId: RiskData.Id,
      Percentage: ActualData.CompleteStatus,
      Note: ActualData.Comment,
      DateModified: ActualData.DateModified,
      UserName: ActualData.UpdatedBy
    }
    setEditData(status);
  }, []);


  if (IsVisible)
    return (
      <>
        
        <RiskStatusTitle RiskId={RiskData.Id} Title='Actual Risk Rating' RiskType='actual' UpdateHandler={loadData} StatusData={editData} HintText={''} />
        <div className="row mt-4">
          <div className="col-md-3"><RootCauses RootCauseData={RootCauseData} ControlsData={ControlsData} HandleHighlight={HandleHighlight} SelectedItems={selectedCauses } /> </div>
          <div className="col-md-6"><Controls ControlsData={ControlsData} HandleHighlight={HandleHighlight} SelectedItems={selectedControls} /></div>
          <div className="col-md-3"><Consequences ConsequenceData={ConsequenceData} ControlsData={ControlsData} HandleHighlight={HandleHighlight} SelectedItems={selectedConsequences} /></div>
        </div>
        <RiskRatingTable RiskData={RiskData} SiteData={SiteData} CurrentData={ActualData} RatingImpactData={RiskImpactRatings} EvaluationType="actual" UpdateHandler={loadData} />
      </>
    );
};

export default ActualRiskRating;