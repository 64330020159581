import { Card, CardBody, CardHeader } from "reactstrap";

const Consequences = ({ ConsequenceData, ControlsData, SelectedItems, HandleHighlight }) => {

  const clickHandler = (ItemId) => {
    console.log("Clicked!", ItemId);
    if (HandleHighlight)
      HandleHighlight("consequences", ItemId);
  }

  return (
    <>
      <Card className='h-100'>
        <CardHeader>
          <h5 className="m-0">Consequences</h5>
        </CardHeader>
        <CardBody>
          <div className='text-center mb-2 text-muted tiny'>When rating each Impact Type, consider only relevant consequences</div>
          <table className="table table-sm table-striped table-hover table-highlight">
            <tbody>
              {ConsequenceData && ConsequenceData.map((item, index) =>
              (
                <tr key={index} onClick={() => clickHandler(item.Id)} className={SelectedItems?.includes(item.Id) ? 'selected' : ''}>
                  <td className="pe-1" style={{ width: "40px" }}>
                    {/*{item.IsUsed ? "" : <><i className="fa-solid fa-link-slash text-danger me-2" title="" id={"brokenConsequence" + index}></i><TooltipComponent target={"brokenConsequence" + index} content={'This consequence has not yet been linked to any control'} /></>}*/}
                    {ControlsData?.find(x => x.ConnectedConsequences.includes(item.Id)) ? (
                      <></>
                    ) : <i className="fa-solid fa-link-slash text-danger me-2" title="This consequence has not yet been linked to any control"></i>}
                  </td>
                  <td>{item.ShortName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </>
  );
};

export default Consequences;