/* eslint-disable no-extend-native */

Date.prototype.toProperDate = function () {
  // Get the year, month, and day
  const year = this.getFullYear();
  const month = String(this.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(this.getDate()).padStart(2, '0');

  // Return the date in yyyy-MM-dd format
  return `${year}-${month}-${day}`;
};