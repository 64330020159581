import React from 'react';
export const CommandTemplate = [
  { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
  { type: 'Delete', buttonOption: { cssClass: 'e-flat text-danger', iconCss: 'e-delete e-icons' } }
];

export const ConfirmDialogStyles = {
  classNames: {
    confirmButton: 'btn btn-sm confirm-button',
    cancelButton: 'btn btn-sm cancel-button',
  }
}

export const YesNoTypes = [
  { value: 0, text: '- Select -' },
  { value: 1, text: 'Yes' },
  { value: 2, text: 'No' },
];

export const CompanySubscriptionTypes = [
  { Id: 0, Text: '- Select -' },
  { Id: 1, Text: 'Free' },
  { Id: 2, Text: 'Pay As You Go' },
  { Id: 3, Text: 'Annual' }
];

export const RatingScaleTypes = [
  { Id: 0, Text: '- Select -' },
  { Id: 1, Text: 'Linear' },
  { Id: 2, Text: 'Exponential' },
];

export const StatusUpdateTypes = [{ Title: "Not Yet Started", Id: 0 }, { Title: "On Track", Id: 1 }, { Title: "Stalling", Id: 2 }, { Title: "Completed", Id: 3 }];
export const FrequencyTypes = [{ Title: "Not Yet Started", Id: 0 }, { Title: "Ad hoc", Id: 1 }, { Title: "Daily", Id: 2 }, { Title: "Twice a week", Id: 3 }, { Title: "Every week", Id: 4 }, { Title: "Every 2 weeks", Id: 5 }, { Title: "Every 3 weeks", Id: 6 }, { Title: "Every 4 weeks", Id: 7 }, { Title: "Every 5 weeks", Id: 8 }, { Title: "Every 6 weeks", Id: 9 }, { Title: "Every 7 weeks", Id: 10 }, { Title: "Every 8 weeks", Id: 11 }];
export const FrequencySelectTypes = [{ Title: "- Select Frequency -", Id: 0 }, { Title: "Ad hoc", Id: 1 }, { Title: "Daily", Id: 2 }, { Title: "Twice a week", Id: 3 }, { Title: "Every week", Id: 4 }, { Title: "Every 2 weeks", Id: 5 }, { Title: "Every 3 weeks", Id: 6 }, { Title: "Every 4 weeks", Id: 7 }, { Title: "Every 5 weeks", Id: 8 }, { Title: "Every 6 weeks", Id: 9 }, { Title: "Every 7 weeks", Id: 10 }, { Title: "Every 8 weeks", Id: 11 }];

export const SearchGridToolbar = ['Search'];

export const FullToolbarSettings = {
  items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
    'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
    'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
    'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
    'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
  ],
  type: 'Expand'
};

export const SimpleToolbarSettings = {
  items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
  type: 'Expand'
};

const getSiteStructureItems = (siteStructureData, parentId) => {
  return siteStructureData.filter(item => item.ParentId === parentId);
}

export const GenerateSiteStructureTree = (siteStructureData, addAll) => {
  let result = [];
  if (addAll)
    result.push({ id: 0, name: "- All -", pid: null, hasChild: false, selected: true, expanded: true, });
  else
    result.push({ id: 0, name: "- Select -", pid: null, hasChild: false, selected: true, expanded: true, });

  siteStructureData.forEach((item) => {
    result.push({ id: item.Id, name: item.Name, pid: (item.ParentId > 0 ? item.ParentId : null), hasChild: (getSiteStructureItems(siteStructureData, item.Id).length > 0) });

  });
  return result;
}

export const GenerateTeamTree = () => { }

export const DarkenHexColor = (hex, percent) => {
  // Remove the hash symbol if present
  hex = hex.replace('#', '');

  // Parse the hex color into its red, green, and blue components
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Darken each component by the specified percentage
  r = Math.floor(r * (1 - percent / 100));
  g = Math.floor(g * (1 - percent / 100));
  b = Math.floor(b * (1 - percent / 100));

  // Ensure values are within 0-255
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  // Convert the components back to a hex string
  const darkenedHex = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();

  return darkenedHex;
}

export const GetContrastColour = (hexColor) => {
  try {
    if (hexColor.indexOf("#") >= 0)
      hexColor = hexColor.substr(1);
    var r = parseInt(hexColor.substr(0, 2), 16);
    var g = parseInt(hexColor.substr(2, 2), 16);
    var b = parseInt(hexColor.substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    //console.log(hexColor, r,g,b,yiq)
    return (yiq >= 128) ? '#000' : '#fff';
  } catch (e) {
    return '#000';
  }
  
}

export const GetRatingBandId = (value, RatingList) => {
  return RatingList.findIndex(function (item) {
    if (item.ImpactTo === 0) {
      // If ImpactTo is 0, assume it's an open-ended range (no upper limit)
      return value >= item.ImpactFrom;
    } else {
      return value >= item.ImpactFrom && value <= item.ImpactTo;
    }
  });
}

export const GetRatingBand = (Rating, RatingList, iv, cv, nl, type, amount) => {
  const bandId = GetRatingBandId(amount, RatingList);
  if (bandId >= 0)
    return RatingList[bandId].ImpactBandName;
  else
    return "No Rating Band";
}

export const GetRatingBandDetail = (Amount, RatingList) => {
  //console.log(Amount, RatingList);
  const bandId = GetRatingBandId(Amount, RatingList);
  if (bandId >= 0)
    return RatingList[bandId];
  else
    return null;
}

export const GetRatingBandOld = (Rating, RatingList, iv, cv, nl, type, amount) => {
  let Band = null;
  let scale = (cv / iv) ** (1 / nl - 2);
  let result = 0;

  if (type === 1) {
    let RiskScore = (amount - iv) / (((cv - iv) / (nl - 2))) + 2;
    result = RiskScore.toFixed(1);
  }
  else
    result = (Math.log(amount / iv) / Math.log(scale)) + 2;
  if (result > 0) {
    if (result > 5)
      //Band = RatingList.find(c => c.Rating === 5);
      Band = RatingList.find(c => c.LevelNumber === 5);
    else
      Band = RatingList.find(c => c.LevelNumber === Math.floor(result));
    //Band = RatingList.find(c => c.Rating === Math.floor(result));
  }
  else
    return "Not Specified"

  if (Band)
    return Band.ImpactBandName;
  //return Band.RatingBandTitle
  return "No Rating Band";
}


export const GetRatingImpact = (Rating, iv, cv, nl, type) => {
  //console.log(Rating)
  let scale = (cv / iv) ** (1 / nl - 2);
  if (Rating > 0) {
    if (type === 1)
      return Math.floor(((cv - iv) / nl - 2) * (Rating - 2) + iv);
    else
      return Math.floor(iv * scale ** (Rating - 2));
  }
  return 0.00;
}

export const GetMaxImpactRating = (RatingList) => {
  if (RatingList && RatingList.length > 0)
    return RatingList.sort((a, b) => b.Rating - a.Rating)[0].Rating;
  else
    return 1;
}

export const GetMaxRatingAmount = (CurrentRating, RatingList) => {
  let Band = null;
  if (CurrentRating > 0)
    Band = RatingList.find(c => c.Rating === CurrentRating);

  if (Band) {
    if (Band.ImpactTo > 0)
      return Band.ImpactTo;
    else if (Band.ImpactFrom > 0)
      return null;
  }
  return 0;
}

export const GetProbabilityValue = (ProbabilityRating) => {
  return ProbabilityRating / 100;
}

export const GetLikelyImpact = (Impact, P) => {
  return Impact * P;
}

export const CalculateTotalLikelyImpact = (data) => {
  let result = 0;
  data.map(item =>
    result += item.LikelyImpact
  );
  return result;
}

export const CalculateOverallRating = (data, iv, cv, nl, type, total) => {
  //console.log(data)
  let scale = (cv / iv) ** (1 / nl - 2);
  //let result = 0;
  if (data) {


    if (type === 1) {
      let totalRiskScore = (total - iv) / (((cv - iv) / (nl - 2))) + 2;
      return totalRiskScore.toFixed(1);
    }
    else {
      let totalRiskScore = (Math.log(total / iv) / Math.log(scale)) + 2;
      return totalRiskScore.toFixed(1);
    }
  } else
    return 0;
}

export const CustomFixedDecimals = (value, IsMoney) => {

  if (IsMoney)
    return value.toFixed(2);

  try {
    let valueStr = value.toString();
    const DecimalPattern = /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/;
    const matches = valueStr.match(DecimalPattern);
    const decimalPart = matches[1] || ''; // Extract the decimal part
    const exponentPart = matches[2] || ''; // Extract the exponent part

    // Combine the decimal part and the exponent part to get the total number of decimal places
    const totalDecimalPlaces = decimalPart.length - (exponentPart ? parseInt(exponentPart, 10) : 0);
    switch (totalDecimalPlaces) {
      case 0:
        return value;
      case 1:
        return value.toFixed(1);
      default:
        return value.toFixed(2);
    }
  } catch (e) {
    console.error(e);
    return value;
  }
}

export const ShowLoader = () => {
  return (
    <React.Fragment>
      <div className="text-center mt-3"><i className='fas fa-spinner fa-spin me-2 fa-2x'></i><br />Loading...</div>
    </React.Fragment>
  );
}