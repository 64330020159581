/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable no-case-declarations */
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Inject, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Count, HtmlEditor, QuickToolbar, RichTextEditorComponent, Toolbar as rteToolbar } from '@syncfusion/ej2-react-richtexteditor';
import { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { SearchGridToolbar, SimpleToolbarSettings } from '../../helpers/global';
import { CalculateLinearStep, CalculateLinearAmount } from '../../helpers/Calculations';
import { GetCatastrophicLabel, GetImmaterialityLabel } from '../../helpers/Lookup';
import { fieldRequired } from '../../helpers/validation';



export default function RiskRatingTables(props) {

  const commandTemplate = [
    { type: 'Setup', buttonOption: { cssClass: 'e-flat', iconCss: 'e-settings e-icons' } },
  ];

  const [editData, setEditData] = useState();
  //const [companyData, setCompanyData] = useState();
  const [impactBands, setImpactBands] = useState();
  const [ratingTable, setRatingTable] = useState();
  const [ratingDescriptions, setRatingDescriptions] = useState();
  const [siteName, setSiteName] = useState();
  const [evaluationPerspectives, setEvaluationPerspectives] = useState();
  const [evaluationsUsed, setEvaluationsUsed] = useState();
  const [editId, setEditId] = useState(0);

  
  const [description, setDescription] = useState('');
  const [useDefault, setUseDefault] = useState('');

  const [levelId, setLevelId] = useState(0);
  //const [bandId, setBandId] = useState(0);
  const [perspectiveId, setPerspectiveId] = useState(0);

  const [immaterialityLevel, setImmaterialityLevel] = useState(0);
  const [catastrophicLevel, setCatastrophicLevel] = useState(0);


  const [currencySymbol, setCurrencySymbol] = useState('');
  

  const ratingScaleUsed = 1;

  //PotentialRiskUsed

  const [editModal, setEditModal] = useState(false);
  const toggle = () => setEditModal(!editModal);

  const [dizModal, setDizModal] = useState(false);
  const toggleDiz = () => setDizModal(!dizModal);



  const commandClick = async (args) => {
    editItem(args.rowData.Id);
  }

  const loadData = async () => {
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();
    let loadCounter = 3;

    try {
      const response = await fetch(`api/sitestructures/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setEditData(data);
        //console.log(data);
        loadCounter--;
        if (loadCounter <= 0)
          this.setState({ loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/companies/${userData.CompanyId}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        //setCompanyData(data);
        setCurrencySymbol(data.CurrencySymbol);

        loadCounter--;
        if (loadCounter <= 0)
          this.setState({ loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }


    //const [impactBands, setImpactBands] = useState();
    //const [evaluationPerspectives, setEvaluationPerspectives] = useState();

    try {
      const response = await fetch(`/api/impactband/getallforcompany/${userData.CompanyId}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setImpactBands(data);
        //console.log(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`/api/evaluationperspectives/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setEvaluationPerspectives(data);
        //console.log(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('/api/impactperspectivediz/listforcompany/' + userData.CompanyId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setRatingDescriptions(data);
        //setLoading(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const loadRatingTableData = async (SiteStructureId) => {
    var bearer = 'Bearer ' + getAuthToken();


    try {
      const response = await fetch(`api/sitestructurelevels/ListForSiteStructure/${SiteStructureId}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data.length);
        //console.log(data[0].EvaluationsUsed);
        if (data.length > 0 && data[0].EvaluationsUsed.length > 0) {
          setEvaluationsUsed(JSON.parse(data[0].EvaluationsUsed));
        }
        else
          setEvaluationsUsed([]);

        for (var i = 0; i < data.length; i++) {
          if (!data[i].EvaluationPerspectiveIds) {
            data[i].EvaluationPerspectiveIds = [];
            data[i].EvaluationPerspectiveDescriptions = [];
            evaluationPerspectives.map((item) => {
              data[i].EvaluationPerspectiveIds.push(item.Id);
              data[i].EvaluationPerspectiveDescriptions.push({ Id: item.Id, Description: "" });
            });
          };
        }

        setRatingTable(data);
        //console.log(evaluationsUsed);
        //console.log(data);

      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const editItem = (id) => {
    //console.log(id);
    if (id > 0) {
      const data = editData.find((item) => { return item.Id === id });
      //console.log(data);
      setEditId(data.Id);
      setSiteName(data.Name);
      //setCompanyId(data.CompanyId);
      //setImmaterialityLevel(data.ImmaterialityLevel);
      setImmaterialityLevel(0); //force to zero now...
      setCatastrophicLevel(data.CatastrophicLevel);
      loadRatingTableData(data.Id);
    }
    else {
      //clear state fields
      setEditId(0);
      //setCompanyId(0);
      //setParentId(0);
      //setName('');
      setImmaterialityLevel(0);
      setCatastrophicLevel(0);
    }
    //console.log(impactBands);
    setEditModal(true);
  };

  const validateForm = () => {
    var valid = true;
    valid &= fieldRequired(catastrophicLevel, 'tbCatastrophicLevelError', '* required');

    return valid;
  }

  const saveItem = async (event) => {
    event.stopPropagation();


    if (validateForm()) {
      let siteStructure = editData.find((element) => element.Id === editId);
      //siteStructure.ImmaterialityLevel = immaterialityLevel;
      siteStructure.ImmaterialityLevel = 0; //always start from zero
      siteStructure.CatastrophicLevel = catastrophicLevel;
      siteStructure.NumOfLevels = impactBands.length;
      let SaveCounter = ratingTable.length;

      var bearer = 'Bearer ' + getAuthToken();

      try {
        const response = await fetch('/api/sitestructures', {
          method: 'PUT',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(siteStructure),
        });
        if (response.ok) {
          await response.json();
          SaveCounter--;
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            window.location.href = "/login";
        }

      } catch (e) {
        console.error(e);
        ToastUtility.show({
          title: 'Site Structures', content: 'There was an error saving the site structure!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }

      for (var i = 0; i < ratingTable.length; i++) {

        ratingTable[i].ImpactFrom = calculateLinearImpactNumber(ratingTable[i].LevelNumber, true);
        const impactTo = calculateLinearImpactNumber(ratingTable[i].LevelNumber, false);
        if (impactTo === 0)
          ratingTable[i].ImpactTo = 999999999999999;
        else
          ratingTable[i].ImpactTo = impactTo;

        ratingTable[i].EvaluationsUsed = JSON.stringify(evaluationsUsed);
        console.log(ratingTable[i]);

        try {
          const response = await fetch('/api/sitestructurelevels', {
            method: ratingTable[i].Id === 0 ? 'POST' : 'PUT',
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Authorization': bearer,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(ratingTable[i]),
          });
          if (response.ok) {
            await response.json();
            SaveCounter--;
          }
          else {
            console.log(response.status + ": " + response.statusText);
            if (response.status === 401)
              window.location.href = "/login";
          }

        } catch (e) {
          console.error(e);
          ToastUtility.show({
            title: 'Impact Rating Table', content: 'There was an error saving the impact rating table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        }
      }
      if (SaveCounter <= 0) {
        loadData();
        editItem(editId);
        ToastUtility.show({
          title: 'Impact Rating Table', content: 'The impact rating table was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
    }
  }

  const calculateLinearImpactNumber = (row, isMinValue) => {
    const step = CalculateLinearStep(0, catastrophicLevel, impactBands.length);
    if (isMinValue)
      row--;

    if (row === impactBands.length && !isMinValue)
      return 0;

    return CalculateLinearAmount(0, step, row);
  }

  const calculateLinearImpact = (row, isMinValue) => {
    if (row === impactBands.length && !isMinValue)
      return "Infinity";
    else {
      const value = calculateLinearImpactNumber(row, isMinValue);
      return currencySymbol + " " + value.toNumberString();
    }
    
  }

  const calculateExponentialImpact = (row, isMinValue) => {
    const step = (catastrophicLevel / immaterialityLevel) ** (1 / (impactBands.length - 2));
    console.log("Exponential Impact")
    row--;
    //console.log(row, step);
    if (row === impactBands.length - 1 && !isMinValue)
      return "Infinity";
    if (row === 0 && isMinValue)
      return currencySymbol + " 0";

    if (isMinValue)
      return currencySymbol + " " + Math.round(immaterialityLevel * step ** (row - 1)).toNumberString();
    else
      return currencySymbol + " " + Math.round(immaterialityLevel * step ** row).toNumberString();
  }

  const getPerspectiveDescriptionWithDefault = (level, band, perspective) => {
    try {
      let table = ratingTable;
      let row = table.find((item) => { return item.LevelNumber === level });
      let idx = row.EvaluationPerspectiveIds.indexOf(perspective);
      let diz = row.EvaluationPerspectiveDescriptions[idx].Description;
      if (diz === "")
        diz = ratingDescriptions.find((item) => { return item.ImpactBandId === band && item.EvaluationPerspectiveId === perspective }).Description;
      return { __html: diz };
    } catch (e) {
      //return "";
      return { __html: "" };
    }
  }

  const editPerspectiveDescription = (level, band, perspective) => {
    console.log(level, band, perspective);
    try {
      let table = ratingTable;
      console.log(table);
      let row = table.find((item) => { return item.LevelNumber === level });
      let idx = row.EvaluationPerspectiveIds.indexOf(perspective);
      let diz = row.EvaluationPerspectiveDescriptions[idx].Description;
      if (diz === "") {
        setUseDefault(true);
        diz = ratingDescriptions.find((item) => { return item.ImpactBandId === band && item.EvaluationPerspectiveId === perspective }).Description;
      }
      else
        setUseDefault(false);
      setDescription(diz);
    } catch (e) {
      setDescription("");
    }
    //setBandId(band);
    setLevelId(level);
    setPerspectiveId(perspective);
    setDizModal(true);

  }

  const saveDescription = () => {
    setDizModal(false);
    //console.log(bandId, levelId, description);
    try {
      let table = ratingTable;
      let rowIdx = table.findIndex((item) => { return item.LevelNumber === levelId });
      let row = table[rowIdx];
      let idx = row.EvaluationPerspectiveDescriptions.findIndex((item) => { return item.Id === perspectiveId });
      if (useDefault)
        table[rowIdx].EvaluationPerspectiveDescriptions[idx].Description = '';
      else
        table[rowIdx].EvaluationPerspectiveDescriptions[idx].Description = description;
    } catch (e) {
      console.error(e);
    }
  }

  const isEvaluationUsed = (PerspectiveId) => {
    if (ratingTable) {
      try {
        return evaluationsUsed.find((item) => { return item === PerspectiveId }) > 0;;

      } catch (e) {
        return false;
      }
    }
    else
      return true;
  }

  const setEvaluationUsed = (PerspectiveId, isUsed) => {
    let evaluations = evaluationsUsed;
    if (isUsed)
      evaluations.push(PerspectiveId);
    else
      evaluations = evaluations.filter((item) => { return item !== PerspectiveId });
    setEvaluationsUsed(evaluations);
    console.log(PerspectiveId, isUsed);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-6 "><h1>Impact Rating Tables</h1></div>
        <div className="col-md-6 text-end align-self-center">
          <a href="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1 mb-2"><i className="far fa-circle-left me-2"></i>Back To Master Data</a>
          <a href="/admin/impact-bands" className="btn btn-sm btn-primary me-2 mb-2"><i className="fa-solid fa-palette me-2"></i>Set Impact Bands</a>
          <a href="/admin/evaluation-perspectives" className="btn btn-sm btn-primary mb-2"><i className="fa-solid fa-chart-simple me-2"></i>Set Evaluation Perspectives</a>

        </div>
      </div>

      <GridComponent dataSource={editData} allowSorting={true} toolbar={SearchGridToolbar} commandClick={commandClick} >
        <ColumnsDirective>
          <ColumnDirective field='Name' width='100' headerText="Site" template={(props) => <><span className={"ps-" + props.LayerId}>{props.Name}</span></>} />
          {/*<ColumnDirective field='ImmaterialityLevel' width='80' headerText={GetImmaterialityLabel() + " Level"} headerTextAlign="right" textAlign="right" template={(props) => <>{currencySymbol + " " + props.ImmaterialityLevel.toNumberString()}</>} />*/}
          <ColumnDirective field='CatastrophicLevel' width='80' headerText={GetCatastrophicLabel() + " Level"} headerTextAlign="right" textAlign="right" template={(props) => <>{currencySymbol + " " + props.CatastrophicLevel.toNumberString()}</>} />
          <ColumnDirective field='NumOfPerspectives' width='60' headerText="Perspectives" headerTextAlign="center" textAlign="center" />
          <ColumnDirective headerText='Actions' width='40' commands={commandTemplate} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>

      <Modal isOpen={editModal} toggle={toggle} scrollable size="xl" backdrop="static">
        <ModalHeader toggle={toggle}>Edit <b>{siteName}</b> Impact Rating Table</ModalHeader>
        <ModalBody>

          <div className="row">
            <div className='mb-3 col-md-3 d-none'>
              <NumericTextBoxComponent id='tbImmaterialityLevel' name='tbImmaterialityLevel' placeholder={GetImmaterialityLabel() + " Level"} floatLabelType='Auto' showClearButton={true} value={immaterialityLevel} onChange={e => setImmaterialityLevel(e.value)} />
              <div id='tbImmaterialityLevelError' className='error-message' />
            </div>

            <div className='mb-3 col-md-3'>
              <NumericTextBoxComponent id='tbCatastrophicLevel' name='tbCatastrophicLevel' placeholder={GetCatastrophicLabel() + " Level"} floatLabelType='Auto' showClearButton={true} value={catastrophicLevel} onChange={e => setCatastrophicLevel(e.value)} />
              <div id='tbCatastrophicLevelError' className='error-message' />
            </div>
          </div>


          <table className="table table-bordered table-sm text-tiny">
            <thead className="bg-secondary">
              <tr>
                <th rowSpan="2">Level</th>
                <th rowSpan="2">Label</th>
                <th rowSpan="2">Colour</th>
                <th colSpan="2" className="text-center">Impact</th>
                <th colSpan={evaluationPerspectives?.length} className="text-center">Impact description per evaluation perspective</th>
              </tr>
              <tr>

                <th className="text-center">Range From<div className="text-small" style={{ width: "90px" }}>(Incl.)</div></th>
                <th className="text-center">Range To <div className="text-small" style={{ width: "90px" }}>(Below)</div></th>

                {evaluationPerspectives?.map((item) => (
                  <th key={item.Id}>
                    <CheckBoxComponent label={item.Title} checked={isEvaluationUsed(item.Id)} change={e => setEvaluationUsed(item.Id, e.checked)} />
                  </th>
                ))}

              </tr>
            </thead>
            <tbody>

              {ratingTable?.map((item) => (
                <tr key={item.LevelNumber}>
                  <td>{item.LevelNumber}.</td>
                  <td>{item.ImpactBandName}</td>
                  <td><div className="colour-block" style={{ backgroundColor: item.ImpactBandColour }}></div></td>
                  <td className="text-end">
                    {ratingScaleUsed === 1 ? calculateLinearImpact(item.LevelNumber, true) : calculateExponentialImpact(item.LevelNumber, true)}
                  </td>
                  <td className="text-end">
                    {ratingScaleUsed === 1 ? calculateLinearImpact(item.LevelNumber, false) : calculateExponentialImpact(item.LevelNumber, false)}
                  </td>
                  {evaluationPerspectives?.map((perspective) => (
                    <td key={perspective.Id} className="hover-edit">
                      <div style={{ width: "250px" }} dangerouslySetInnerHTML={getPerspectiveDescriptionWithDefault(item.LevelNumber, item.ImpactBandId, perspective.Id)} />
                      <div className="edit-button" onClick={() => editPerspectiveDescription(item.LevelNumber, item.ImpactBandId, perspective.Id)}><i className="fa-solid fa-pen-to-square"></i></div>
                      {/*<TextBoxComponent placeholder='No description specified' type='text' className="ratetable" maxLength='2500' floatLabelType='Never' showClearButton={true} width={250} value={getPerspectiveDescriptionWithDefault(item.LevelNumber, item.ImpactBandId, perspective.Id)} onChange={e => setPerspectiveDescription(item.LevelNumber, perspective.Id, e.value)} /> */}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

        </ModalBody>

        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={dizModal} toggle={toggleDiz} scrollable size="lg" backdrop="static">
        <ModalHeader toggle={toggleDiz}>Edit Description</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <CheckBoxComponent label="Use default company description" checked={useDefault} change={e => setUseDefault(e.checked)} />
          </div>
          <RichTextEditorComponent id="descriptionText" placeholder='Use Default Description' toolbarSettings={SimpleToolbarSettings} showCharCount={false} value={description} enabled={!useDefault} change={e => setDescription(e.value)}  >
            <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
          </RichTextEditorComponent>

        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggleDiz}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={saveDescription}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>

      <br /><br />
    </>
  );
}

