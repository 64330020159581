import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { createRef, useEffect, useState } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { ConfirmDialogStyles } from '../../../helpers/global';
import EngagementModal from '../EngagementModal';

const BusinessProcesses = ({ RiskId, ProcessData, EventHandler }) => {

  const [businessProcessId, setBusinessProcessId] = useState(0);
  const [processes, setProcesses] = useState();
  const [riskStatus, setRiskStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);

  const toggle = () => setEditModal(!editModal);

  const statusRef = createRef();

  const saveItem = async (event) => {
    event.stopPropagation();
    const userData = getUserDetails();

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: 0, RiskId: RiskId, BusinessProcessId: businessProcessId }
    console.log(data);
    try {
      const response = await fetch('api/riskbusinessprocesses/' + userData.UserRole, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        if (EventHandler)
          EventHandler();
        setBusinessProcessId(0);
        loadData();

        ToastUtility.show({
          title: 'Risk Business Processes', content: 'The business process was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }
      setEditModal(false);
    } catch (e) {
      console.error(e);
      ToastUtility.show({
        title: 'Risk Business Processes', content: 'There was an error saving the business process!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
      setEditModal(false);
    }
  }

  const loadData = async () => {
    const User = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch(`api/businessprocesses/listallbycompany/${User.CompanyId}/${User.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        const filteredData = data.filter(item => {
          return !ProcessData?.some(ob => ob.BusinessProcessId === item.Id);
        });
       
        setProcesses(filteredData);

      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    loadRiskStatus();

  }

  const loadRiskStatus = async () => {
    const User = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch(`api/riskbusinessprocessstatus/getbyriskid/${RiskId}/${User.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log("Business Objectives status: ", data)
        setRiskStatus(data);
        setLoading(false);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }
  }

  const deleteItem = async (dataId) => {
    if (await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles)) {
      const bearer = 'Bearer ' + getAuthToken();
      const userData = getUserDetails();
      try {
        const response = await fetch('/api/riskbusinessprocesses/' + userData.UserRole + "/" + dataId, {
          method: 'DELETE',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          await response.json();
          loadData();
          if (EventHandler)
            EventHandler();
          ToastUtility.show({
            title: 'Risk Business Processes', content: 'The business process was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            window.location.href = "/login";
        }
      } catch (e) {
        console.error(e);
        this.setState({ loading: false });
        ToastUtility.show({
          title: 'Risk Business Processes', content: 'There was an error deleting the business process!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    }
  }


  useEffect(() => {
    loadData();
  }, []);


if (!loading)
  return (
    <>
      
        <div className='text-center'><small>{riskStatus?.Percentage > 0 ? `${riskStatus?.Percentage}% - ${new Date(riskStatus?.DateModified).toISOString().split("T")[0]}` : "Not Yet Started"}</small></div>
        <div className='card h-100'>
          <div className='card-header'>
            <table className="w-100">
              <tbody>
                <tr>
                  <td>Business Processes </td>
                  <td className="text-end"><i className="fa-regular fa-square-plus" onClick={toggle} title='Add Business Process'></i></td>
                </tr>
              </tbody>
            </table>
            <small>(Click the <strong>"+"</strong> icon to add a Business Process)</small>
            </div>
            
          <div className='card-body'>
            
            <div className='d-flex flex-column justify-content-between'>
              {ProcessData && ProcessData.map((item, index) => (
                <div key={index} className='d-flex justify-content-between align-items-center'>
                  <span className=''>{item.BusinessProcessName}</span><i onClick={() => deleteItem(item.Id)} className="far fa-trash-can text-danger" title='Remove Business Process'></i>
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-center fw-bold'>
              
              
            </div>
          </div>
          <div className="card-footer text-center">
            {ProcessData?.length > 0 ? <button onClick={() => statusRef?.current.showModal()} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
          </div>
        </div>
      

      <Modal isOpen={editModal} toggle={toggle} scrollable size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>Business Processes</ModalHeader>
        <ModalBody>
          <div className='mb-3'>
            <DropDownListComponent id='ddBusinessProcessId' name='ddBusinessProcessId' placeholder='Add a Business Process' dataSource={processes?.length > 0 ? [{ ShortName: "-SELECT-", Id: 0 }, ...processes] : processes} fields={{ text: 'ShortName', value: 'Id' }} floatLabelType='Always' value={businessProcessId} change={e => setBusinessProcessId(e.value)} /><div id='tbSelectError' className='error-message' />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>

      <EngagementModal key="mdlStatus" ref={statusRef} Title="Business Process Status" EngagementSaved={loadRiskStatus} Data={{ Id: RiskId, PreviousPercentage: riskStatus.Percentage, UserName: riskStatus.UserName, PreviousDate: riskStatus.DateModified, PreviousComment: riskStatus.Note, Route: 'api/riskbusinessprocessstatus/' + getUserDetails().UserRole }} />
    </>
  );
};

export default BusinessProcesses;