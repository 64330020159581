import { useEffect, useState } from 'react';
import RiskRatingTable from "../Common/RiskRatingTable";
import RiskStatusTitle from '../Common/RiskStatusTitle';

const TargetRiskRating = ({ RiskData, SiteData, RiskImpactRatings, TargetData, IsVisible, OnStatusUpdate }) => {
  const [editData, setEditData] = useState();

  const loadData = async () => {
    if (OnStatusUpdate)
      OnStatusUpdate();
}

  useEffect(() => {

    const status = {
      RiskId: RiskData.Id,
      Percentage: TargetData.CompleteStatus,
      Note: TargetData.Comment,
      DateModified: TargetData.DateModified,
      UserName: TargetData.UpdatedBy
    }
    setEditData(status);
  }, []);

  if (IsVisible)
    return (
      <>
        <RiskStatusTitle RiskId={RiskData.Id} Title='Target Risk Rating' RiskType='target' UpdateHandler={loadData} StatusData={editData} HintText={''} />
        <RiskRatingTable RiskData={RiskData} SiteData={SiteData} CurrentData={TargetData} RatingImpactData={RiskImpactRatings} EvaluationType="target" UpdateHandler={loadData} />
      </>
    );
};

export default TargetRiskRating;